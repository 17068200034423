import { render, staticRenderFns } from "./CustomerCategory.vue?vue&type=template&id=403bc227&scoped=true&"
import script from "./CustomerCategory.vue?vue&type=script&lang=js&"
export * from "./CustomerCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403bc227",
  null
  
)

export default component.exports