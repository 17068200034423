import axios from "axios";
import {processHttpErrors} from "@/helpers/commonHelper";
import Vue from "vue";
import {saveFile} from "@/helpers/mediaHelper";

export class CustomerService{
    formDataCustomer = {};
    formDataCustomerCategory = {};

    constructor() {
        this.formDataCustomer = {};
        this.formDataCustomerCategory = {}
    }

    //customer===
    async getDefaultCustomer(fetch){
        let storeDefaultCustomer = Vue.prototype.$store.getters['common/defaultCustomer'];
        if(!storeDefaultCustomer || fetch){
            let searchResponse = await this.searchCustomer({editable: 0, limit: 1});
            if(searchResponse.customers.length > 0){
                Vue.prototype.$store.dispatch('common/setDefaultCustomer', {value: searchResponse.customers[0]});
                return {isSuccess: true, message: '', customers: searchResponse.customers};
            }else{
                return {isSuccess: false, message: 'Something went wrong!', customers: []};
            }
        }
        return {isSuccess: true, message: '', customers: [storeDefaultCustomer]};
    }
    async searchCustomer(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customer.customers, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, customers: response.data.customers};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', customers: []};
            });
    }
    async getCustomer(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customer.customer, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, customer: response.data.customer};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', customer: null};
            });
    }

    async saveCustomer(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customer.customerSave, this.formDataCustomer).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.customer.customerSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', customer: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, customer: response.data.customer};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', customer: null};
        });
    }

    async deleteCustomer(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customer.customerDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //category===
    async getCustomerCategories(fetch) {
        let customerCategories = Vue.prototype.$store.getters['common/customerCategories'];
        if(customerCategories.length <= 0 || fetch){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.customerCategory.customerCategories)
                .then((response) => {
                    if(response.data.status === 'success'){
                        customerCategories = response.data.customerCategories;
                        Vue.prototype.$store.dispatch('common/setCustomerCategories', {value: customerCategories});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, customerCategories: customerCategories};
                })
                .catch((error) => {
                    processHttpErrors(error, false);
                    return {isSuccess: false, message: 'Something went wrong!', customerCategories: null};
                });
        }
        return {isSuccess: true, message: '', customerCategories: customerCategories};
    }
    async getCustomerCategory(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customerCategory.customerCategory, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, customerCategory: response.data.customerCategory};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', customerCategory: null};
            });
    }
    async saveCustomerCategory(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customerCategory.customerCategorySave, this.formDataCustomerCategory).then(async (response) => {
            await this.getCustomerCategories(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, customerCategory: response.data.customerCategory};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', customerCategory: null};
        });
    }
    async deleteCustomerCategory(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.customerCategory.customerCategoryDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getCustomerCategories(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}