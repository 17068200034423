//company
import Vue from "vue";

export function getStoresList() {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.company.stores;
}
export function getStoreData(id) {
    let user = Vue.prototype.$store.getters['auth/user'];
    return user.company.stores.find(x => x.id === id);
}