//print
export function printDiv(elementId) {
    // Get HTML to print from element
    const prtHtml = document.getElementById(elementId).innerHTML;

    // Get all stylesheets HTML
    let stylesHtml = '';
    for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
    }

    // Open the print window
    const WinPrint = window.open('', '', 'left=0,top=0,width=1280,height=900,toolbar=0,scrollbars=0,status=0');

    WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                  <title>${this.title + ' - ' + this.id}</title>
                    ${stylesHtml}
                  </head>
                  <body style="font-size: 16px;">
                    ${prtHtml}
                  </body>
                </html>`);

    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    setTimeout(function(){
        WinPrint.close();
    }, 1000);
}