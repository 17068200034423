<template>
    <div>
        <div class="row pt-2 mb-2" v-if="isPos">
            <div class="col-sm-4 font-size-25">
                <img src="images/logo-acc-transparent-50w.png" /> PoS
            </div>
            <div class="col-sm-8 text-right">
                <div class="btn-group btn-group-sm" role="group">
                    <button type="button" class="btn btn-dark btn-sm" data-widget="fullscreen" title="Full Screen"><i class="fas fa-expand-arrows-alt"></i></button>
                    <button type="button" class="btn btn-dark btn-sm" title="New Sale" v-on:click="initialize"><i class="fas fa-sync-alt"></i></button>

                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-dark btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                            <i class="fas fa-bolt"></i>
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" style="min-width: 10px; transform: translate3d(-65px, 31px, 0px);">
                            <router-link :to="{ name: isSale? 'App.Sales': 'App.Purchases'}" class="dropdown-item btn-sm" title="Exit PoS"><i class="fas fa-sign-out-alt"></i> Exit PoS</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ValidationObserver v-slot="{ invalid }">
            <form @submit="saveForm" ref="form">
                <div class="row">
                    <div class="col-sm-3" ref="sale_info_card_body">

                        <div class="card card-secondary" v-if="!isPos">
                            <div class="card-header">
                                <h3 class="card-title">{{ title }}</h3>
                            </div>
                            <div class="card-body">
                                <div class="form-group">
                                    <label for="ref">Ref#</label>
                                    <input type="text" class="form-control" id="ref" placeholder="Reference #" v-model="formData.ref">
                                </div>
                                <div class="form-group">
                                    <ValidationProvider v-slot="{ errors }" rules="required">
                                        <label for="date">Date *</label>
                                        <input type="datetime-local" class="form-control" id="date" placeholder="Select date" v-model="formData.date" required>
                                        <span>{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                                <div class="form-group">
                                    <label for="note">Note</label>
                                    <textarea id="note" class="form-control" rows="1" v-model="formData.note"></textarea>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                            <label for="upload_image">Image</label>
                                            <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                        </div>
                                        <div class="col-sm-6" v-if="formData.image">
                                            <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                            <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ProductCategoryCatalog v-if="isPos" :catalog-select-func="catalogSelect" :store-id="formData.store_id"></ProductCategoryCatalog>

                        <div class="card card-secondary">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>{{ isSale? 'Customer': 'Supplier' }} *</label>
                                    <div class="row">
                                        <vSelect @search="searchParty" :filterable="false" :clearable="false" label="name" :options="parties" v-model="party" v-on:option:selected ="setParty" :class="canCreateParty? 'col-10': 'col-12'">
                                            <template v-slot:option="option">
                                                {{ option.name }}
                                                <div class="text-sm">{{ option.mobile }}</div>
                                            </template>
                                            <template v-slot:no-options="{ search, searching }">
                                                <template v-if="searching">
                                                    No results found for <em>{{ search }}</em>.
                                                </template>
                                                <em style="opacity: 0.5;" v-else>Start typing to search.</em>
                                            </template>
                                        </vSelect>
                                        <div class="col-2" v-if="canCreateParty">
                                            <button type="button" class="btn btn-primary btn-sm" v-on:click="showAddPartyModal"><i class="fas fa-plus"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <ValidationProvider v-slot="{ errors }" rules="required">
                                    <div class="form-group">
                                        <label for="store_id" class="col-form-label">Store *</label>
                                        <select class="form-control form-control-sm" id="store_id" v-model="formData.store_id" v-on:change="clearProducts" required :disabled="formId != 0">
                                            <option v-for="store in stores" :key="store.id" :value="store.id">{{ store.name }}</option>
                                        </select>
                                        <span>{{ errors[0] }}</span>
                                    </div>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-9">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">Items</h3>
                                <div class="card-tools">
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">
                                            <button type="button" class="btn btn-default" v-on:click="showAddProductModal" v-if="productSearch.canAddProduct"><i class="fas fa-plus"></i></button>
                                        </div>
                                        <input type="text" id="product_search" class="form-control float-right" placeholder="Enter product name or id" autocomplete="off" v-on:keydown="searchProductOnEnter" v-model="productSearch.keyword" ref="productSearchInput">
                                        <div class="input-group-append">
                                            <button type="button" class="btn btn-default" id="product_search_btn" v-on:click="searchProduct"><i class="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body table-responsive" v-bind:style="styles.items_card_body">
                                <table class="table table-sm" v-if="isSale? formData.sale_items.length > 0: formData.purchase_items.length > 0">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th style="width: 10px">#</th>
                                        <th style="width: 20%">Name</th>
                                        <th>Code</th>
                                        <th class="text-right">Rate</th>
                                        <th class="text-right">Qty</th>
                                        <th class="text-right">Subto.</th>
                                        <th class="text-right">Disc.</th>
                                        <th class="text-right" style="width: 10%">Disc. Type</th>
                                        <th class="text-right">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in isSale? formData.sale_items: formData.purchase_items" :key="item.id" :ref="'item_'+index">
                                        <td><button type="button" class="btn btn-xs btn-default text-danger" v-on:click="removeItemRow(item.sl)"><i class="far fa-times-circle"></i></button></td>
                                        <td>{{ item.sl }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.code }}</td>
                                        <td class="text-right">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <input type="text" class="form-control text-right" v-model="item.rate" v-on:keyup="calculateItemRows" required />
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </td>
                                        <td class="text-right">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <input type="text" class="form-control text-right" v-model="item.qty" v-on:keyup="calculateItemRows" required />
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </td>
                                        <td class="text-right">{{ item.sub_total }}</td>
                                        <td class="text-right">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <input type="text" class="form-control text-right" v-model="item.discount" v-on:keyup="calculateItemRows" required />
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </td>
                                        <td class="text-right">
                                            <select class="form-control" v-model="item.discount_type" v-on:change="calculateItemRows">
                                                <option value="1">Flat</option>
                                                <option value="2">%</option>
                                            </select>
                                        </td>
                                        <td class="text-right">{{ roundNumberV1(item.grand_total) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer">
                                <div class="row" id="totals_container">
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="sub_total">Subtoal</label>
                                            <input id="sub_total" class="form-control text-right" v-bind:value="getCurrencyFormattedVal(roundNumberV1(formData.sub_total))" disabled>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">
                                        <div class="form-group">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <label for="discount">Discount</label>
                                                <input id="discount" class="form-control text-right" type="text" v-model="formData.discount" v-on:keyup="calculateTotals" required>
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="discount_type">Discount Type</label>
                                            <select id="discount_type" class="form-control" v-model="formData.discount_type" v-on:change="calculateTotals">
                                                <option value="1">Flat</option>
                                                <option value="2">%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-1">
                                        <div class="form-group">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <label for="tax">Tax</label>
                                                <input id="tax" class="form-control text-right" type="text" v-model="formData.tax" v-on:keyup="calculateTotals" required>
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="tax_type">Tax Type</label>
                                            <select id="tax_type" class="form-control" v-model="formData.tax_type" v-on:change="calculateTotals">
                                                <option value="1">Flat</option>
                                                <option value="2">%</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <ValidationProvider v-slot="{ errors }" rules="positiveFPN">
                                                <label for="shipping_charge">Shipping</label>
                                                <input id="shipping_charge" class="form-control text-right" type="text" v-model="formData.shipping_charge" v-on:keyup="calculateTotals" required>
                                                <span>{{ errors[0] }}</span>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="col-sm-2">
                                        <div class="form-group">
                                            <label for="grand_total">Grand Total</label>
                                            <input id="grand_total" class="form-control text-right" v-bind:value="getCurrencyFormattedVal(roundNumberV1(formData.grand_total))" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12" v-if="saveBtnEnabled">
                                        <button type="submit" class="btn btn-sm btn-primary float-right" v-if="saveBtnEnabled && !isPos" :disabled="invalid"><i class="fas fa-save"></i> Save</button>

                                        <div v-if="isPos">
                                            <button type="button" class="btn float-right mr-2" :disabled="invalid" v-for="payment_method in payment.paymentMethods" :key="payment_method.id" :style="{ color: payment_method.btn_text_color, backgroundColor: payment_method.btn_bg_color }" v-on:click="paymentMethodSelect(payment_method)"><i :class="payment_method.btn_icon"></i> {{ payment_method.name }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </ValidationObserver>

        <ModalGeneral title="Select Product" size="modal-lg" :showModal="productSearch.showModal" :hideModal="hideProductSearchModal">
            <div slot="modal-body" v-on:keydown="searchProductChangeFocus">
                <div class="list-group product-select-modal">
                    <div class="d-flex justify-content-between align-items-center mb-2"><span>Product</span><span>Purchase Rate</span><span>Sell Rate</span></div>
                    <a href="javascript:void(0);" class="list-group-item d-flex justify-content-between align-items-center list-group-item-action" v-for="(product, index) in productSearch.products" :key="product.id" :ref="'modal_product_'+index" v-on:click="searchProductSelect(index)">
                        <span>
                            <img :src="$apiBaseURL + '/' + product.image" class="img-sm mr-1" v-if="product.image" />
                            <img src="/images/placeholder.jpg" class="img-sm mr-1" v-else="" />
                            {{product.name}} x <span :class="product.balance < 1? 'text-danger': ''" class="text-bold">{{product.balance}}</span>
                        </span>
                        <span class="badge badge-pill">{{ getCurrencyFormattedVal(product.purchase_rate)}}</span>
                        <span class="badge badge-pill">{{ getCurrencyFormattedVal(product.sell_rate)}}</span>
                    </a>
                </div>
            </div>
        </ModalGeneral>
        <ModalGeneral title="Add new Customer" size="modal-lg" :showModal="addParty.showModal" :hideModal="hideAddPartyModal" v-if="addParty.showModal">
            <div slot="modal-body">
                <customer :id="0" :showFormTitle="false" :save-handler="addPartySaveHandler" v-if="isSale"></customer>
                <supplier :id="0" :showFormTitle="false" :save-handler="addPartySaveHandler" v-if="!isSale"></supplier>
            </div>
        </ModalGeneral>
        <ModalGeneral title="Add new Product" size="modal-lg" :showModal="addProduct.showModal" :hideModal="hideAddProductModal" v-if="addProduct.showModal">
            <div slot="modal-body">
                <product :id="0" :showFormTitle="false" :save-handler="addProductSaveHandler"></product>
            </div>
        </ModalGeneral>

        <ModalGeneral title="Payment" size="modal-md" :showModal="payment.showModal" :hideModal="hidePaymentModal" v-if="payment.showModal">
            <div slot="modal-body">
                <ValidationObserver v-slot="{ invalid }">
                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <label for="cheque_no">Cheque No *</label>
                            <input type="text" class="form-control" id="cheque_no" placeholder="Enter the cheque number" v-model="formData.payment.cheque_no" required>
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <label for="cheque_date">Cheque Date *</label>
                            <input type="date" class="form-control" id="cheque_date" placeholder="Enter the cheque date" v-model="formData.payment.cheque_date" required>
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div v-if="selectedPaymentMethod && selectedPaymentMethod.isCheque">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <label for="cheque_bank">Cheque Bank *</label>
                            <input type="text" class="form-control" id="cheque_bank" placeholder="Enter the cheque bank" v-model="formData.payment.cheque_bank" required>
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <div v-if="selectedPaymentMethod && (!selectedPaymentMethod.isCheque && !selectedPaymentMethod.isCash)">
                        <ValidationProvider v-slot="{ errors }" rules="required">
                            <div class="form-group">
                                <label for="other_description">Description *</label>
                                <textarea class="form-control" id="other_description" placeholder="Enter a description of the transaction" v-model="formData.payment.description" required></textarea>
                            </div>
                            <span>{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                    <button type="button" class="btn btn-primary float-right mr-2" :disabled="invalid" v-on:click="$refs.form.requestSubmit()">Save</button>
                </ValidationObserver>
            </div>
        </ModalGeneral>

        <ModalGeneral :title="printModal.title" :size="printModal.thermalPrint? 'modal-md': 'modal-lg'" :showModal="printModal.showModal" :hideModal="hidePrintModal" v-if="printModal.showModal">
            <div slot="modal-body">
                <print-form :form-type="printModal.formType" :form-category="printModal.formCategory" :id="printModal.id" :thermal-print="printModal.thermalPrint"></print-form>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
import Vue from 'vue'
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import ModalGeneral from "../../../components/app/dialog/ModalGeneral";

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import Customer from "../../../components/app/customer/customer";
import Product from "../../../components/app/product/product";
import {getPermissionByKey} from "@/helpers/userHelper";
import {CustomerService} from "@/services/customer-service";
import {SupplierService} from "@/services/supplier-service";
import {StoreService} from "@/services/store-service";
import {ProductService} from "@/services/product-service";
import {SaleService} from "@/services/sale-service";
import {PurchaseService} from "@/services/purchase-service";
import {PaymentService} from "@/services/payment-service";
import {getFormCategoryName} from "@/helpers/commonHelper";
import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
import {isInvoiceThermalPrint} from "@/helpers/settingsHelper";
import Supplier from "@/components/app/supplier/supplier";
import {AccountService} from "@/services/account-service";
import ProductCategoryCatalog from "@/components/app/product/ProductCategoryCatalog";
import PrintForm from "@/components/app/common/PrintForm";
import {roundNumberV1} from "@/helpers/numberHelper";
import {convertLocalToUTC, convertUTCToLocal, getDateTimeToday} from "@/helpers/dateHelper";

dayjs.extend(customParseFormat);


extend('required', {
    ...required,
    message: 'This field is required'
});
extend('positiveFPN', value => {
    let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
    return value >= 0 && regexFloatingPoint.test(value)? true: 'This field must be a positive number';
});

export default {
    name: "SPForm",
    data: function(){
        return {
            pageName: 'Sale',

            customerService: new CustomerService(),
            supplierService: new SupplierService(),
            storeService: new StoreService(),
            productService: new ProductService(),
            saleService: new SaleService(),
            purchaseService: new PurchaseService(),
            accountService: new AccountService(),
            paymentService: new PaymentService(),

            isSale: true,
            isPurchase: false,
            isOrder: false,
            isInvoice: false,
            isDelivery: false,
            isReturn: false,
            title: '',
            canCreateParty: false,

            styles:{
                items_card_body: {
                    height: '0px',
                }
            },

            parties: [],
            party: null,
            stores: [],

            upload_image: null,
            formData: {},
            saveBtnEnabled: false,

            productSearch: {
                keyword: '',
                showModal: false,
                products: [],
                balances: [],
                focusIndex: 0,
                canAddProduct: getPermissionByKey(this.$globalSettings.permissions.product.product_create),
            },
            addProduct: {
                showModal: false,
            },
            addParty: {
                showModal: false,
            },

            payment: {
                paymentMethods: [],
                cashAccount: null,
                showModal: false,
            },
            printModal:{
                title: 'Print',
                showModal: false,
                formType: this.formType,
                formCategory: this.formCategory,
                id: null,
                thermalPrint: false,
            },
        }
    },
    props: {
        formType:{
            type: Number,
            default: () => 1
        },
        formCategory: {
            type: Number,
            default: () => 3
        },
        formId:{
            type: Number,
            default: () => 0
        },
        isPos: {
            type: Boolean,
            default: () => false
        },
        refForm: {
            type: Object,
            default: () => {return {id: 0, type: null}}
        },
    },
    components: {
        PrintForm,
        ProductCategoryCatalog,
        Supplier,
        Product,
        Customer,
        vSelect, ValidationObserver, ValidationProvider, ModalGeneral
    },
    computed: {
        selectedPaymentMethod: function () {
            return this.payment.paymentMethods.find(x => x.id === this.formData.payment.payment_method_id);
        },
    },
    methods:{
        getCurrencyFormattedVal,
        roundNumberV1,
        //Party ====
        searchParty: async function (search, loading) {
            if (search.length) {
                loading(true);
                let loader = this.$loading.show({container: this.$refs.formContainer});

                let partyResponse = this.isSale? await this.customerService.searchCustomer({keyword: search, limit: 5}):  await this.supplierService.searchSupplier({keyword: search, limit: 5});
                this.parties = this.isSale? partyResponse.customers: partyResponse.suppliers;
                loader.hide();
                loading(false);
            }
        },
        setParty: function(party){
            if(this.isSale){
                this.formData.customer_id = party.id;
            }else{
                this.formData.supplier_id = party.id;
            }
            
            this.saveValidation();
        },
        loadDefaultParty: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});

            let partyResponse = this.isSale? await this.customerService.getDefaultCustomer(false): await this.supplierService.getDefaultSupplier(false);
            if(this.isSale){
                //console.log(partyResponse);
                if(partyResponse.customers.length > 0){
                    this.parties = partyResponse.customers;
                    this.party = partyResponse.customers[0];
                    this.formData.customer_id = partyResponse.customers[0].id;
                }
            }else{
                if(partyResponse.suppliers.length > 0){
                    this.parties = partyResponse.suppliers;
                    this.party = partyResponse.suppliers[0];
                    this.formData.supplier_id = partyResponse.suppliers[0].id;
                }
            }
            loader.hide();
        },
        showAddPartyModal: function(){
            this.addParty.showModal = true;
        },
        hideAddPartyModal: function(){
            this.addParty.showModal = false;
            this.focusProductSearchInput();
        },
        addPartySaveHandler: function(party){
            this.hideAddPartyModal();
            if(this.isSale) {
                this.formData.customer_id = party.id;
            }else{
                this.formData.supplier_id = party.id;
            }
            this.parties.push(party);
            this.party = party;
            this.saveValidation();
        },

        //Store ===
        loadStores: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let storesResponse = await this.storeService.getStores(false);
            loader.hide();
            if (storesResponse.isSuccess) {
                this.stores = storesResponse.stores;
                if (this.stores.length > 0) {
                    if(!this.formData.store_id){
                        this.formData.store_id = this.stores[0].id;
                        if(this.isPos) {
                            this.formData.payment.store_id = this.stores[0].id;
                            console.log(this.formData.payment.store_id);
                        }
                    }
                }else{
                    Vue.$toast.open({message: 'You need at least one store to continue!', type: 'error'});
                    await this.$router.push( { name: this.isSale? 'App.Sales': 'App.Purchases'});
                }
            }else{
                Vue.$toast.open({message: storesResponse.message, type: 'error'});
                await this.$router.push( { name: this.isSale? 'App.Sales': 'App.Purchases'});
            }
        },
        clearProducts: function(){
            if(this.isSale){
                this.formData.sale_items = [];
            }else{
                this.formData.purchase_items = [];
            }
            this.calculateItemRows();
            Vue.$toast.open({ message: 'Store change has cleared your items.',  type: 'default'});
        },

        //Product search ====
        focusProductSearchInput: function (){
            if(this.$refs.productSearchInput) {
                this.$refs.productSearchInput.focus();
            }
        },
        searchProductOnEnter: async function (e) {
            if (e.keyCode === 13 || e.keyCode === 50) {
                e.preventDefault();
                await this.searchProduct();
            }
        },
        searchProduct: async function () {
            let loader = this.$loading.show();
            this.productSearch.focusIndex = 0;

            let productResponse = await this.productService.searchProduct({
                keyword: this.productSearch.keyword,
                limit: 5,
                store_id: this.formData.store_id
            }, null);

            this.productSearch.products = productResponse.products;
            this.productSearch.balances = productResponse.balances;
            this.searchProductPrepareBalances();
            if (this.productSearch.products.length === 1) {
                this.searchProductSelect(0);
            } else if (this.productSearch.products.length > 1) {
                this.showProductSearchModal();
            } else {
                Vue.$toast.open({message: "No product found!", type: 'error'});
            }
            loader.hide();
        },
        searchProductPrepareBalances: function(){
            for(let i = 0; i < this.productSearch.products.length; i++){
                this.productSearch.products[i]['balance'] = this.productSearch.balances.find(x => x.product_id === this.productSearch.products[i].id).balance;
            }
            //console.log(this.productSearch.products);
        },
        searchProductChangeFocus: function(e){
            if (e.keyCode === 38 || e.keyCode === 9){
                if(this.productSearch.focusIndex - 1 >= 0){
                    this.productSearch.focusIndex--;
                    this.$refs['modal_product_'+this.productSearch.focusIndex][0].focus();
                }
            }else if (e.keyCode === 40 ){
                if(this.productSearch.focusIndex + 1 < this.productSearch.products.length){
                    this.productSearch.focusIndex++;
                    this.$refs['modal_product_'+this.productSearch.focusIndex][0].focus();
                }
            }
        },
        searchProductSelect: function(index){
            let searchProduct = this.productSearch.products[index];
            if(searchProduct) {
                console.log(searchProduct);
                //console.log('this.isPurchase', this.isPurchase);
                if( (this.isSale && ( (this.isInvoice && searchProduct.balance > 0) || !this.isInvoice ) ) || (this.isPurchase && ( (this.isReturn && searchProduct.balance > 0) || !this.isReturn ) ) ){
                    this.addItemRow(searchProduct);
                    this.hideProductSearchModal();
                }else{
                    Vue.$toast.open({ message: searchProduct.name + " - stock unavailable in the current Store!",  type: 'error'});
                }

                /*if((this.isPurchase && !this.isReturn) || (this.isPurchase && this.isReturn && searchProduct.balance > 0) || (this.isSale && this.isReturn) || (this.isSale && !this.isReturn && searchProduct.balance > 0)) {
                    this.addItemRow(searchProduct);
                    this.hideProductSearchModal();
                }else{
                    Vue.$toast.open({ message: "Stock unavailable in the current Store!",  type: 'error'});
                }*/
            }else{
                Vue.$toast.open({ message: "Selected product is invalid!",  type: 'error'});
            }
        },
        showProductSearchModal: function(){
            this.productSearch.showModal = true;
            this.$nextTick(() => {
                //console.log(this.$refs);
                this.$refs['modal_product_0'][0].focus();
            });
        },
        hideProductSearchModal: function(){
            this.productSearch.showModal = false;
            this.productSearch.keyword = '';
            this.focusProductSearchInput();
        },

        //Product Catalog
        catalogSelect: function(product){
            if(product) {
                if(product.balance > 0) {
                    this.addItemRow(product);
                }else{
                    Vue.$toast.open({ message: "Stock unavailable!",  type: 'error'});
                }
            }else{
                Vue.$toast.open({ message: "Selected product is invalid!",  type: 'error'});
            }
            this.$refs.productSearchInput.focus();
        },

        //Product Row Add/Remove ====
        addItemRow(product){
            let addItemRow = true;
            let items = this.isSale? this.formData.sale_items: this.formData.purchase_items;
            items.forEach(item => {
                if (item.product_id === product.id) {
                    item.qty++;
                    addItemRow = false;
                }
            });
            if (addItemRow) {
                items.push({ product_id: product.id, qty: 1, rate: this.isSale? product.sell_rate: product.purchase_rate, sub_total: 0, discount: 0, discount_type: 1, grand_total: 0,  code: product.code,  name: product.name });
            }

            this.calculateItemRows();
            Vue.$toast.open({ message: product.name + ' added to the list',  type: 'default'});
        },
        removeItemRow(sl){
            if(confirm("Are you sure?")) {
                if(this.isSale){
                    this.formData.sale_items = this.formData.sale_items.filter(obj => obj.sl !== sl);
                }else{
                    this.formData.purchase_items = this.formData.sale_items.filter(obj => obj.sl !== sl);
                }
                this.calculateItemRows();
            }
        },

        //Product Add ====
        showAddProductModal(){
            this.addProduct.showModal = true;
        },
        hideAddProductModal: function(){
            this.addProduct.showModal = false;
            this.focusProductSearchInput();
        },
        addProductSaveHandler: function(product){
            this.hideAddProductModal();
            this.addItemRow(product);
            this.saveValidation();
        },

        //Calculations =====
        calculateItemRows: function(){
            this.formData.sub_total = 0;
            let items = this.isSale? this.formData.sale_items: this.formData.purchase_items;
            items.forEach((item, index) => {
                item['sl'] = index + 1;
                item.sub_total = item.qty * item.rate;
                if (item.discount > 0) {
                    if (item.discount_type == 1) {
                        item.grand_total = item.sub_total - item.discount;
                    } else {
                        item.grand_total = item.sub_total - ((item.discount / 100) * item.sub_total);
                    }
                } else {
                    item.grand_total = item.sub_total;
                }

                this.formData.sub_total += Number(item.grand_total);
            });

            this.calculateTotals();
        },
        calculateTotals: function(){
            this.formData.grand_total = this.formData.sub_total;
            if(this.formData.discount > 0){
                if (this.formData.discount_type == 1) {
                    this.formData.grand_total -= Number(this.formData.discount);
                } else {
                    this.formData.grand_total -= Number((this.formData.discount / 100) * this.formData.sub_total);
                }
            }

            if(this.formData.tax > 0){
                if (this.formData.tax_type == 1) {
                    this.formData.grand_total += Number(this.formData.tax);
                } else {
                    this.formData.grand_total += Number((this.formData.tax / 100) * this.formData.sub_total);
                }
            }

            if(this.formData.shipping_charge > 0){
                this.formData.grand_total += Number(this.formData.shipping_charge);
            }

            this.saveValidation();
        },

        //Form Load / Save ===
        saveValidation: function(){
            let items = this.isSale? this.formData.sale_items: this.formData.purchase_items;
            this.saveBtnEnabled = !!(items.length > 0 && (this.isSale? this.formData.customer_id: this.formData.supplier_id));
        },
        async loadSavedData() {
            let formIdToLoad = 0;
            formIdToLoad = !this.isRefForm? this.formId: this.refForm.id;

            if (formIdToLoad !== 0) {
                let loader = this.$loading.show();

                let form = this.isSale? await  this.saleService.getSale(formIdToLoad, 'edit'): await this.purchaseService.getPurchase(formIdToLoad, 'edit');
                if(form.isSuccess){
                    if(!this.isRefForm){
                        this.formData = this.isSale? form.sale: form.purchase;
                        this.title = (this.isSale? 'Sale ': 'Purchase ') + getFormCategoryName(this.formCategory) + ' #' + this.formId;

                        //console.log(this.formData.date);
                        this.formData.date = convertUTCToLocal(this.isSale? form.sale.date: form.purchase.date);
                        //console.log(`converted to Local: ${this.formData.date}`);
                    }else{
                        let formData = this.isSale? form.sale: form.purchase;
                        if(this.isSale){
                            this.formData.customer = formData.customer;
                            this.formData.sale_items = formData.sale_items;
                        }else{
                            this.formData.supplier = formData.supplier;
                            this.formData.purchase_items = formData.purchase_items;
                        }

                        this.formData.ref = formData.ref;
                        this.formData.note = formData.note;
                        this.formData.store_id = formData.store_id;
                        if(this.refForm.type === this.$globalEnums.formCategories.Order){
                            this.formData.ref = (this.isSale? 'Sale ': 'Purchase ') +  'Order #' + this.refForm.id;
                            this.formData.ref_order_id = this.refForm.id;
                        }
                        if(this.refForm.type === this.$globalEnums.formCategories.Invoice){
                            this.formData.ref = (this.isSale? 'Sale ': 'Purchase ') +  'Invoice #' + this.refForm.id;
                            this.formData.ref_invoice_id = this.refForm.id;
                        }
                    }

                    let items = this.isSale? this.formData.sale_items: this.formData.purchase_items;
                    items.forEach(item => {
                        item.name = item.product.name;
                        item.code = item.product.code;
                    });

                    this.party = {
                        id: this.isSale? this.formData.customer.id: this.formData.supplier.id,
                        name: this.isSale? this.formData.customer.name: this.formData.supplier.name,
                        mobile: this.isSale? this.formData.customer.mobile: this.formData.supplier.mobile
                    };
                    this.parties.push(this.party);
                    //console.log(this.formData);
                    this.calculateItemRows();
                }else{
                    Vue.$toast.open({message: form.message, type: 'error'});
                    await this.$router.push( { name: this.isSale? 'App.Sales': 'App.Purchases'});
                }
                loader.hide();
            }
        },
        setUploadFile(){
            this.upload_image = this.$refs.upload_image.files[0];
        },
        async saveForm(e) {
            e.preventDefault();
            let loader = this.$loading.show();

            if(this.isPos){
                this.formData.payment.amount = this.formData.grand_total;
                this.formData.payment.type = this.$globalEnums.paymentTypes.Sale;
                this.formData.payment.payment_transactions = [{
                    amount: this.formData.grand_total,

                    from_id: this.isSale? this.party.id: this.payment.cashAccount.id,
                    from_type: this.isSale? 'customer': 'account',
                    from_description: null,
                    from_name: this.isSale? this.party.name: this.payment.cashAccount.name,

                    to_id: this.isSale? this.payment.cashAccount.id: this.party.id,
                    to_type: this.isSale? 'account': 'supplier',
                    to_description: null,
                    to_name: this.isSale? this.payment.cashAccount.name: this.party.name,
                }];

                console.log('payment.store_id', this.formData.payment.store_id);
            }

            let saveResponse;
            if(this.isSale){
                //console.log(this.formData.date);
                this.formData.date = convertLocalToUTC(this.formData.date);
                //console.log(`converted to UTC: ${this.formData.date}`);
                this.saleService.formDataSale = this.formData;
                saveResponse = await this.saleService.saveSale(this.upload_image);
            }else{
                this.purchaseService.formDataPurchase = this.formData;
                saveResponse = await this.purchaseService.savePurchase(this.upload_image);
            }
            if (saveResponse.isSuccess) {
                Vue.$toast.open({message: saveResponse.message, type: 'success'});
                if(this.isPos){
                    this.print(this.isSale? saveResponse.sale: saveResponse.purchase);
                    await this.initialize();
                }else{
                    await this.$router.push( { name: this.isSale? 'App.Sales': 'App.Purchases'});
                }
            }else{
                Vue.$toast.open({message: saveResponse.message, type: 'error'});
            }
            loader.hide();
        },
        unsetUploadFile(){
            if(confirm("Are you sure?")) {
                this.formData.image = null;
            }
        },

        //Style ====
        fixStyleIssues: function (){
            if(this.$refs.sale_info_card_body) {
                this.styles.items_card_body.height = (this.$refs.sale_info_card_body.clientHeight/1.34) + 'px';
            }
        },

        //POS ========
        //Add Payment boilerplate in formData
        prepareFormPaymentBase: function (){
            this.formData.payment = {
                id: 0,
                amount: null,
                payment_date: getDateTimeToday(),
                ref: null,
                invoice_no: null,
                type: null,
                payment_method_id: null,
                cheque_no: null,
                cheque_date: getDateTimeToday(),
                cheque_bank: null,
                description: null,
                image: null,
                store_id: null,
                payment_transactions: [],
            };
        },
        loadPaymentMethods: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await this.paymentService.getPaymentMethods(false);
            loader.hide();
            if(response.isSuccess){
                this.payment.paymentMethods = response.paymentMethods;
            }else{
                Vue.$toast.open({message: response.message, type: 'error'});
            }
        },
        paymentMethodSelect(paymentMethod, submit){
            this.formData.payment.payment_method_id = paymentMethod.id;
            if(paymentMethod.isCash){
                this.$refs.form.requestSubmit();
            }else{
                if(submit){
                    this.$refs.form.requestSubmit();
                }else {
                    this.payment.showModal = true;
                }
            }
        },
        hidePaymentModal(){
            this.payment.showModal = false;
        },
        print(data){
            this.printModal.thermalPrint = false;
            if(data.type === this.$globalEnums.formCategories.Invoice && isInvoiceThermalPrint()){
                this.printModal.thermalPrint = true;
            }
            //sale "type" is "formCategory" on the frontend
            this.printModal.formCategory = data.type;
            this.printModal.showModal = true;
            this.printModal.id = data.id;
        },
        hidePrintModal(){
            this.printModal.showModal = false;
        },

        //Initializations ================
        loadCashAccount: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await this.accountService.getDefaultCashAccount(false);
            loader.hide();
            if (response.isSuccess && response.accounts.length > 0) {
                this.payment.cashAccount = response.accounts[0];
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
                await this.$router.push( { name: this.isSale? 'App.Sales': 'App.Purchases'});
            }
        },
        prepareFormMeta(formType, formCategory){
            this.isSale = formType === this.$globalEnums.formTypes.Sale;
            this.isPurchase = formType === this.$globalEnums.formTypes.Purchase;
            this.isDelivery = formCategory === this.$globalEnums.formCategories.Delivery;
            this.isOrder = formCategory === this.$globalEnums.formCategories.Order;
            this.isInvoice = formCategory === this.$globalEnums.formCategories.Invoice;
            this.isDelivery = formCategory === this.$globalEnums.formCategories.Delivery;
            this.isReturn = formCategory === this.$globalEnums.formCategories.Return;
            this.isRefForm = this.refForm.id !== 0;
        },
        async initialize() {
            this.prepareFormMeta(this.formType, this.formCategory);
            /*let saleService = new SaleService();
            let purchaseService = new PurchaseService();*/
            this.formData = this.isSale ? {
                id: 0,
                ref: null,
                customer_id: null,
                date: getDateTimeToday() ,
                sub_total: 0,
                discount: 0,
                discount_type: 1,
                tax: 0,
                tax_type: 1,
                shipping_charge: 0,
                grand_total: 0,
                note: null,
                image: null,
                payment_status: 0,
                shipping_status_id: null,
                type: null,
                ref_order_id: null,
                ref_invoice_id: null,
                user_id: null,
                store_id: null,
                status: 1,
                sale_items: [],
            } : {
                id: 0,
                ref: null,
                supplier_id: null,
                date: getDateTimeToday() ,
                sub_total: 0,
                discount: 0,
                discount_type: 1,
                tax: 0,
                tax_type: 1,
                shipping_charge: 0,
                grand_total: 0,
                note: null,
                image: null,
                payment_status: 0,
                shipping_status_id: null,
                type: null,
                ref_order_id: null,
                ref_invoice_id: null,
                user_id: null,
                store_id: 0,
                status: 1,
                purchase_items: [],
            };

            this.parties = [];
            this.party = null;

            if (this.formId === 0) {
                this.formData.type = this.formCategory;
                await this.loadDefaultParty();
                if (this.isPos) {
                    await this.loadCashAccount();
                }
            }
            if (this.isPos) {
                await this.loadPaymentMethods();
                this.prepareFormPaymentBase();
            }else{
                await this.loadSavedData();
            }

            await this.loadStores();
            this.focusProductSearchInput();
        },
    },
    async created() {
        await this.initialize();
    },
    async mounted() {
        this.canCreateParty = getPermissionByKey(this.isSale? this.$globalSettings.permissions.customer.customer_create: this.$globalSettings.permissions.supplier.supplier_create);

        this.fixStyleIssues();
        this.title = (this.isSale? 'New Sale ': 'New Purchase ') + getFormCategoryName(this.formCategory);
    },
}
</script>

<style scoped>
[class*="icheck-"] > input:first-child + label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 17px;
    border: 1px solid #D3CFC8;
    border-top-color: rgb(211, 207, 200);
    border-right-color: rgb(211, 207, 200);
    border-bottom-color: rgb(211, 207, 200);
    border-left-color: rgb(211, 207, 200);
    border-radius: 0;
    margin-left: -17px;
}


[class*="icheck-"] > input:first-child:checked + label::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: -3px;
    left: -1px;
    width: 7px;
    height: 11px;
    border: 2px solid #fff;
    border-top-color: rgb(255, 255, 255);
    border-top-style: solid;
    border-top-width: 2px;
    border-left-color: rgb(255, 255, 255);
    border-left-style: solid;
    border-left-width: 2px;
    border-left: none;
    border-top: none;
    -webkit-transform: translate(7.75px,4.5px) rotate(45deg);
    transform: translate(7.75px,4.5px) rotate(45deg);
    -ms-transform: translate(7.75px,4.5px) rotate(45deg);
}

[class*="icheck-"] > label {
    padding-left: 19px !important;
    line-height: 22px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}
</style>