<template>
    <div class="card card-secondary">
        <div class="card-body">
            <div class="row catalog_body" ref="catalog_body" v-if="mode === 'category'">
                <div class="col-4 text-center catalog" v-for="category in categories" :key="category.id" v-on:click="selectCategory(category)">
                    <div class="catalog_image_container">
                        <img :src="$apiBaseURL + '/' + category.image" class="img-thumbnail mr-1" v-if="category.image" />
                        <img src="/images/placeholder.jpg" class="img-thumbnail mr-1" v-else />
                    </div>
                    <div class="catalog_details_container">
                        <div class="catalog_details_name">{{category.name}}</div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-sm btn-light mb-2" v-on:click="showCategories(false)" v-if="mode === 'product'"><i class="fas fa-arrow-left"></i></button>
            <div class="row catalog_body" ref="catalog_body" v-if="mode === 'product'">
                <div class="col-4 text-center catalog" v-for="product in products" :key="product.id" v-on:click="catalogSelectFunc(product)">
                    <div class="catalog_image_container">
                        <img :src="$apiBaseURL + '/' + product.image" class="img-thumbnail mr-1" v-if="product.image" />
                        <img src="/images/placeholder.jpg" class="img-thumbnail mr-1" v-else />
                    </div>
                    <div class="catalog_details_container">
                        <div class="catalog_details_code">{{product.code}}</div>
                        <div class="catalog_details_name">{{product.name}}</div>
                    </div>
                </div>
            </div>
            <div id="catalog_pagination" class="row mt-3" v-if="mode === 'product' && last_page > 1">
                <div class="col-4">
                    <button type="button" class="btn btn-sm btn-light" v-on:click="loadProducts('prev')" :disabled="current_page <= 1"><i class="fas fa-arrow-left"></i></button>
                </div>
                <div class="col-4 text-center">
                    {{ current_page }}/{{ last_page }}
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-sm btn-light float-right" v-on:click="loadProducts('next')" :disabled="current_page >= last_page"><i class="fas fa-arrow-right"></i></button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {ProductService} from "@/services/product-service";

let productService = new ProductService();
export default {
    name: "ProductCategoryCatalog",
    data: function() {
        return {
            categories: [],
            category_id: null,
            brands: [],
            brand_id: null,
            limit: 8,
            products: [],
            balances: [],
            current_page: 1,
            last_page: 1,
            mode: 'category',
        }
    },
    props:{
        catalogSelectFunc:{
            type: Function,
            default: () => ()=>{
                return false;
            }
        },
        storeId:{
            type: Number,
            default: () => null
        },
    },
    methods:{
        loadProducts: async function (type) {
            let loader = this.$loading.show();
            if (type === 'init') {
                this.current_page = 1;
            } else if (type === 'prev') {
                if (this.current_page > 1) {
                    this.current_page--;
                }
            } else if (type === 'next') {
                if (this.current_page < this.last_page) {
                    this.current_page++;
                }
            }

            let productResponse = await productService.searchProduct({
                category_id: this.category_id,
                brand_id: this.brand_id,
                limit: this.limit,
                store_id: this.storeId
            }, this.current_page);
            loader.hide();

            if(productResponse.isSuccess){
                this.products = productResponse.products;
                this.balances = productResponse.balances;
                this.last_page = productResponse.last_page;
                
                this.searchProductPrepareBalances();
            }else{
                Vue.$toast.open({message: productResponse.message, type: 'error'});
                await this.$router.push({name: 'App.Sales'});
            }
        },
        searchProductPrepareBalances: function(){
            for(let i = 0; i < this.products.length; i++){
                this.products[i]['balance'] = this.balances.find(x => x.product_id === this.products[i].id).balance;
            }
            //console.log(this.products);
        },
        loadBrands: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await productService.getBrands(false);
            //console.log(response);
            loader.hide();
            if(response.isSuccess){
                this.brands = response.productBrands;
                this.brands = [{id: null, name: 'All Brands'}, ...this.brands];
            }else{
                Vue.$toast.open({message: response.message, type: 'error'});
                await this.$router.push({name: 'App.Sales'});
            }
        },
        loadCats: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let response = await productService.getCats(false);
            loader.hide();
            if (response.isSuccess) {
                this.categories = response.productCats;
                //this.categories = [{id: null, name: 'All Categories'}, ...this.categories];
            } else {
                Vue.$toast.open({message: response.message, type: 'error'});
                await this.$router.push({name: 'App.Sales'});
            }
        },
        showCategories: async function (loadCats) {
            this.mode = 'category';
            this.category_id = null;
            if(loadCats) {
                await this.loadCats();
            }
        },
        selectCategory: async function (category) {
            this.products = [];
            this.category_id = category.id;
            this.mode = 'product';
            await this.loadProducts('init');
        }
    },
    async mounted() {
        //await this.loadProducts('init');
        //await this.loadBrands();
        await this.showCategories(true);
    }
}
</script>

<style scoped>
    .catalog_body{
        height: 325px;
        overflow-y: scroll;
    }
</style>