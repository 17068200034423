<template>
    <div>
        <div class="row mb-2 pb-2 border-bottom border-info" v-if="!thermalPrint">
            <div class="col-sm-4 order-sm-2 print-align-right sm-align-center md-align-right">
                <img :src="$apiBaseURL + '/' + logo" class="img-size-50" v-if="logo">
                <div class="font-italic text-xs">ovopos.com</div>
            </div>
            <h4 class="col-sm-4 text-center text-uppercase pt-2 order-sm-1">
                {{ title }}
            </h4>
            <div class="col-sm-4 order-sm-0 sm-align-center">
                <div class="text-bold">{{ name }}</div>
                <div class="text-sm">{{ store_name }} Store</div>
                <div class="text-sm">{{ store_address }}</div>
                <div class="text-sm">{{ store_phone }}</div>
                <div class="text-sm">{{ store_email }}</div>
            </div>
        </div>
        <div class="text-center" v-else>
            <img :src="$apiBaseURL + '/' + logo" class="img-size-50" v-if="logo">
            <div>{{ name }}</div>
            <div class="text-bold">{{ title }}</div>
            <div>{{ store_name }} Store</div>
            <div>{{ store_address }}</div>
            <div>{{ store_phone }}</div>
            <div>{{ store_email }}</div>
        </div>
    </div>
</template>

<script>
import {getCompanyValByKey} from "@/helpers/companyHelper";
    export default {
        name: "PrintHeader",
        data: function() {
            return {
                logo: null,
            }
        },
        props:{
            title: {
                type: String,
                default: ()=> ''
            },
            thermalPrint:{
                type: Boolean,
                default: ()=> false
            },
            name:{
                type: String,
                default: ()=> getCompanyValByKey('name')
            },
            store_name:{
                type: String,
                default: ()=> 'Main'
            },
            store_address:{
                type: String,
                default: ()=> getCompanyValByKey('address')
            },
            store_phone:{
                type: String,
                default: ()=> getCompanyValByKey('phone')
            },
            store_email:{
                type: String,
                default: ()=> getCompanyValByKey('email')
            },
        },
        mounted() {
            this.logo = getCompanyValByKey('image');
        }
    }
</script>

<style scoped>

</style>