<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
        <BreadCrumb :segments="segments" :page-name="pageName"></BreadCrumb>
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <SPForm :form-type="formType" :form-category="formCategory" :form-id="formId" :is-pos="false" :ref-form="refForm"></SPForm>

            </div><!--/. container-fluid -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from "../../../components/app/common/BreadCrumb";
    import SPForm from "@/components/app/common/SPForm";

    export default {
        name: 'Purchase_view',
        data: function(){
            return {
                pageName: 'Purchase',
                segments: [
                    { link: true, text: 'Purchases', routeName: 'App.Purchases' },
                    { link: false, text: 'Purchase' },
                ],
                formType: this.$globalEnums.formTypes.Purchase,
                formCategory: this.$globalEnums.formCategories.Invoice,
                formId: 0,
                refForm: {
                    id: 0,
                    type: null
                }
            }
        },
        props: {},
        components: {
            BreadCrumb, SPForm
        },
        computed: {

        },
        methods:{

        },
        created() {
            this.formId = this.$route.params.id;
            this.formCategory = this.$route.params.type;
            this.refForm = this.$route.params.refForm;
        }
    }
</script>

<style scoped>
    [class*="icheck-"] > input:first-child + label::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 16px;
        height: 17px;
        border: 1px solid #D3CFC8;
        border-top-color: rgb(211, 207, 200);
        border-right-color: rgb(211, 207, 200);
        border-bottom-color: rgb(211, 207, 200);
        border-left-color: rgb(211, 207, 200);
        border-radius: 0;
        margin-left: -17px;
    }


    [class*="icheck-"] > input:first-child:checked + label::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: -3px;
        left: -1px;
        width: 7px;
        height: 11px;
        border: 2px solid #fff;
        border-top-color: rgb(255, 255, 255);
        border-top-style: solid;
        border-top-width: 2px;
        border-left-color: rgb(255, 255, 255);
        border-left-style: solid;
        border-left-width: 2px;
        border-left: none;
        border-top: none;
        -webkit-transform: translate(7.75px,4.5px) rotate(45deg);
        transform: translate(7.75px,4.5px) rotate(45deg);
        -ms-transform: translate(7.75px,4.5px) rotate(45deg);
    }

    [class*="icheck-"] > label {
        padding-left: 19px !important;
        line-height: 22px;
        display: inline-block;
        position: relative;
        vertical-align: top;
        margin-bottom: 0;
        font-weight: 400;
        cursor: pointer;
    }
</style>
