<template>
    <div id="print_area">
        <div v-if="formData" :class="{'thermal-print font-size-12': thermalPrint}">
            <print-header :title="title" v-if="!thermalPrint"></print-header>
            <print-header :title="title" :thermal-print="thermalPrint" :store_name="store.name" :store_address="store.address" :store_phone="store.phone" :store_email="store.email" v-else></print-header>
            <div class="row" :class="{'border-dashed-bottom pb-2': thermalPrint}">
<!--                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Store:</span>
                    <span> {{ store.name }}</span>
                </div>-->
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>{{ isSale? 'Customer': 'Supplier' }}:</span>
                    <span> {{ isSale? formData.customer.name: formData.supplier.name }}</span> <span>{{isSale? formData.customer.address: formData.supplier.address}}</span> <span>{{ isSale? formData.customer.phone: formData.supplier.phone }}</span> <span>{{ isSale? formData.customer.email: formData.supplier.email }}</span>
                </div>
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Date:</span>
                    <span> {{ formData.date }}</span>
                </div>
                <div class="col-sm-4 mb-2" v-if="!thermalPrint && isInvoice">
                    <span>Payment:</span>
                    <span> {{ paymentStatus }}</span>
                </div>
                <div class="col-sm-4 mb-2" v-if="!thermalPrint && !isDelivery">
                    <span>Total:</span>
                    <span> {{ getCurrencyFormattedVal(roundNumberV1(formData.grand_total)) }}</span>
                </div>
                <div class="col-sm-4 mb-2" v-if="!thermalPrint && isInvoice">
                    <span>Due:</span>
                    <span> {{ getCurrencyFormattedVal(roundNumberV1(due)) }}</span>
                </div>
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Serial #:</span>
                    <span> {{ formData.id }}</span>
                </div>
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Reference:</span>
                    <span> {{ formData.ref }}</span>
                </div>
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Created:</span>
                    <span> {{ formData.created_at }}</span>
                </div>
                <div :class="{'col-sm-12': thermalPrint, 'col-sm-4 mb-2': !thermalPrint}">
                    <span>Updated:</span>
                    <span> {{ formData.updated_at }}</span>
                </div>
            </div>

            <table class="table table-sm mt-3" v-if="!thermalPrint && ((formType === $globalEnums.formTypes.Sale && simplified_items.length > 0) || (formType === $globalEnums.formTypes.Purchase && formData.purchase_items.length > 0))">
                <thead>
                <tr>
                    <th style="width: 10px">#</th>
                    <th>Name</th>
                    <th class="text-right">Code</th>
                    <th class="text-right" v-if="!isDelivery">Rate</th>
                    <th class="text-right">Qty</th>
                    <th class="text-right" v-if="!isDelivery">Subtotal</th>
                    <th class="text-right" v-if="!isDelivery">Disc.</th>
                    <th class="text-right" style="width: 10%" v-if="!isDelivery">Type</th>
                    <th class="text-right" v-if="!isDelivery">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in simplified_items" :key="item.id" :ref="'item_'+index">
                    <td>{{ item.sl }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-right">{{ item.code }}</td>
                    <td class="text-right" v-if="!isDelivery">{{ getCurrencyFormattedVal(item.rate) }}</td>
                    <td class="text-right">{{ item.qty }}</td>
                    <td class="text-right" v-if="!isDelivery">{{ getCurrencyFormattedVal(roundNumberV1(item.sub_total)) }}</td>
                    <td class="text-right" v-if="!isDelivery">{{ item.discount }}</td>
                    <td class="text-right" v-if="!isDelivery">{{ item.discount_type === 1? 'Flat': '%' }}</td>
                    <td class="text-right" v-if="!isDelivery">{{ getCurrencyFormattedVal(roundNumberV1(item.grand_total)) }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr v-if="!isDelivery">
                    <td class="text-right" colspan="8">Subtotal</td>
                    <td class="text-right">{{ getCurrencyFormattedVal(roundNumberV1(formData.sub_total)) }}</td>
                </tr>
                <tr v-if="!isDelivery">
                    <td class="text-right" colspan="8">Discount</td>
                    <td class="text-right">{{ formData.discount_type === 1? getCurrencyFormattedVal(formData.discount): (formData.discount + '%') }}</td>
                </tr>
                <tr v-if="!isDelivery">
                    <td class="text-right" colspan="8">Tax</td>
                    <td class="text-right">{{ formData.tax_type === 1? getCurrencyFormattedVal(formData.tax): (formData.tax + '%') }}</td>
                </tr>
                <tr v-if="!isDelivery">
                    <td class="text-right" colspan="8">Shipping</td>
                    <td class="text-right">{{ getCurrencyFormattedVal(formData.shipping_charge)}}</td>
                </tr>
                <tr v-if="!isDelivery">
                    <td class="text-right" colspan="8">Grand Total</td>
                    <td class="text-right text-bold">{{ getCurrencyFormattedVal(roundNumberV1(formData.grand_total)) }}</td>
                </tr>
                <tr v-if="formCategory === $globalEnums.formCategories.Delivery">
                    <td class="text-right" colspan="3">Grand Total</td>
                    <td class="text-right text-bold">{{ totalQty }}</td>
                </tr>
                </tfoot>
            </table>

            <div v-else>
                <div class="row border-dashed-bottom">
                    <div class="col-sm-7 font-bold">Item</div>
                    <div class="col-sm-5 font-bold text-right">Total</div>
                </div>
                <div class="row border-dashed-bottom py-1" v-for="(item, index) in simplified_items" :key="item.id" :ref="'item_'+index">
                    <div class="col-sm-7">
                        <div>{{ item.name }}</div>
                        <div>{{ item.qty }} <span v-if="!isDelivery">@{{ item.rate }}</span></div>
                        <span v-if="item.discount > 0 && !isDelivery">-{{ item.discount }}{{ item.discount_type === 1? '': '%' }}</span>
                    </div>
                    <div class="col-sm-5 text-right" v-if="!isDelivery">{{ roundNumberV1(item.grand_total) }}</div>
                </div>
            </div>
            <div class="mt-2" v-if="thermalPrint">
                <div v-if="!isDelivery">
                    <span>Subtotal:</span>
                    <span> {{ getCurrencyFormattedVal(roundNumberV1(formData.sub_total)) }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Discount:</span>
                    <span> {{ formData.discount_type === 1? getCurrencyFormattedVal(formData.discount): (formData.discount + '%') }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Tax:</span>
                    <span> {{ formData.tax_type === 1? getCurrencyFormattedVal(formData.tax): (formData.tax + '%') }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Shipping:</span>
                    <span> {{ getCurrencyFormattedVal(formData.shipping_charge) }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Grand Total:</span>
                    <span class="font-bold"> {{ getCurrencyFormattedVal(roundNumberV1(formData.grand_total)) }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Method:</span>
                    <span v-for="(payment, i) in payments" :key="i"> {{ payment.payment_method.name }} </span>
                </div>
                <div v-if="!isDelivery">
                    <span>Payment:</span>
                    <span> {{ paymentStatus }}</span>
                </div>
                <div v-if="!isDelivery">
                    <span>Due:</span>
                    <span> {{ getCurrencyFormattedVal(roundNumberV1(due)) }}</span>
                </div>
                <div v-if="formCategory === $globalEnums.formCategories.Delivery">
                    <span>Grand Total:</span>
                    <span class="font-bold"> {{ totalQty }}</span>
                </div>
            </div>
            <div class="mt-2" v-if="formData.note">Note: {{ formData.note }}</div>
            <div class="font-italic text-xs text-center mt-2">OVOPoS.com</div>
        </div>
        <button type="button" class="btn btn-sm btn-secondary d-print-none mt-5" v-on:click="printDiv('print_area')"><i class="fas fa-print"></i></button>
    </div>
</template>

<script>
    import Vue from 'vue';
    import dayjs from 'dayjs';
    import customParseFormat from 'dayjs/plugin/customParseFormat';
    dayjs.extend(customParseFormat);
    import {getCurrencyFormattedVal} from "@/helpers/currencyHelper";
    import {getDateFormattedVal, convertUTCToLocalWithFormat} from "@/helpers/dateHelper";
    import {printDiv} from "@/helpers/printHelper";
    import {getStoreData} from "@/helpers/storeHelper";
    import PrintHeader from "./PrintHeader";
    import {SaleService} from "@/services/sale-service";
    import {PurchaseService} from "@/services/purchase-service";
    import {PaymentService} from "@/services/payment-service";
    import {roundNumberV1} from "@/helpers/numberHelper";

    let saleService = new SaleService();
    let purchaseService = new PurchaseService();
    let paymentService = new PaymentService();
    export default {
        name: "PrintForm",
        data: function() {
            return {
                formData: {
                    customer: {},
                    supplier: {},
                    sale_items: [],
                    purchase_items: [],
                },
                simplified_items: [],
                title: '',

                store: {},

                payments: [],
                paymentsTotal: 0,
            }
        },
        props:{
            formType:{
                type: Number,
                default: () => 1
            },
            formCategory:{
                type: Number,
                default: () => 3
            },
            id:{
                type: Number,
                default: () => null
            },
            thermalPrint:{
                type: Boolean,
                default: () => false
            },
        },
        computed: {
            isSale: function(){
                return this.formType === this.$globalEnums.formTypes.Sale;
            },
            isPurchase: function(){
                return this.formType === this.$globalEnums.paymentTypes.Purchase;
            },
            isDelivery: function(){
                return this.formCategory === this.$globalEnums.formCategories.Delivery;
            },
            isInvoice: function(){
                return this.formCategory === this.$globalEnums.formCategories.Invoice;
            },
            due: function () {
                //console.log(this.formData.grand_total);
                return this.formData.grand_total - this.paymentsTotal;
            },
            paymentStatus: function () {
                return this.due > 0? (this.due < this.formData.grand_total? 'Partial': 'Due') : 'Paid';
            },
            totalQty: function () {
                let total = 0;
                if(this.simplified_items.length > 0){
                    this.simplified_items.forEach((item) => {
                        total += Number(item.qty);
                    })
                }
                return total;
            },
            paymentsType: function () {
                if(this.isSale){
                    if(this.formCategory === this.$globalEnums.formCategories.Invoice){
                        return this.$globalEnums.paymentTypes.Sale;
                    }else if(this.formCategory === this.$globalEnums.formCategories.Return){
                        return this.$globalEnums.paymentTypes.SalesReturn;
                    }else{
                        return this.$globalEnums.paymentTypes.Receive;
                    }
                }else{
                    if(this.formCategory === this.$globalEnums.formCategories.Invoice){
                        return this.$globalEnums.paymentTypes.Purchase;
                    }else if(this.formCategory === this.$globalEnums.formCategories.Return){
                        return this.$globalEnums.paymentTypes.PurchaseReturn;
                    }else{
                        return this.$globalEnums.paymentTypes.Pay;
                    }
                }
            },
        },
        components: {PrintHeader},
        methods:{
            printDiv,
            getCurrencyFormattedVal,
            roundNumberV1,
            loadSavedData: async function () {
                let loader = this.$loading.show({container: this.$refs.formContainer});
                let response = this.isSale? await saleService.getSale(this.id, 'view'): await purchaseService.getPurchase(this.id, 'view');
                if (response.isSuccess) {
                    let paymentResponse = await paymentService.searchPayment({
                        invoiceNo: this.id,
                        type: this.paymentsType,
                    });
                    if (paymentResponse.isSuccess) {
                        this.payments = paymentResponse.payments;
                        this.payments.forEach(payment => {
                            payment.payment_transactions.forEach(payment_transaction => {
                                this.paymentsTotal += Number(payment_transaction.amount);
                            });
                        });
                    }else{
                        Vue.$toast.open({message: response.message, type: 'error'});
                    }

                    let formCategories = this.isSale? this.$globalEnums.formCategories: this.$globalEnums.formCategories;
                    console.log(formCategories);
                    this.title = (this.isSale? 'Sale ': 'Purchase ') + Object.keys(formCategories).find(key => formCategories[key] === this.formCategory);

                    if(this.isSale){
                        this.formData = response.sale;
                        this.simplified_items = this.formData.sale_items;
                    }else{
                        this.formData = response.purchase;
                        this.simplified_items = this.formData.purchase_items;
                    }
                    this.simplified_items.forEach((item, index) => {
                        item['sl'] = index + 1;
                        item.name = item.product.name;
                        item.code = item.product.code;
                    });

                    this.formData.date = getDateFormattedVal(this.isSale? response.sale.date: response.purchase.date);
                    this.formData.created_at = convertUTCToLocalWithFormat(this.formData.created_at);
                    this.formData.updated_at = convertUTCToLocalWithFormat(this.formData.updated_at);

                    this.store = getStoreData(this.formData.store_id);
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                }
                loader.hide();
            }
        },
        mounted() {
            this.loadSavedData();
        }
    }
</script>

<style scoped>

</style>