import axios from "axios";
import {processHttpErrors} from "@/helpers/commonHelper";
import Vue from "vue";
import {saveFile} from "@/helpers/mediaHelper";

export class SupplierService{
    formDataSupplier = {};
    formDataSupplierCategory = {};

    constructor() {
        this.formDataSupplier = {};
        this.formDataSupplierCategory = {}
    }

    //supplier===
    async getDefaultSupplier(fetch){
        let storeDefaultSupplier = Vue.prototype.$store.getters['common/defaultSupplier'];
        if(!storeDefaultSupplier || fetch){
            let searchResponse = await this.searchSupplier({editable: 0, limit: 1});
            if(searchResponse.suppliers.length > 0){
                Vue.prototype.$store.dispatch('common/setDefaultSupplier', {value: searchResponse.suppliers[0]});
                return {isSuccess: true, message: '', suppliers: searchResponse.suppliers};
            }else{
                return {isSuccess: false, message: 'Something went wrong!', suppliers: []};
            }
        }
        return {isSuccess: true, message: '', suppliers: [storeDefaultSupplier]};
    }
    async searchSupplier(filters){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplier.suppliers, filters)
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, suppliers: response.data.suppliers};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', suppliers: []};
            });
    }
    async getSupplier(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplier.supplier, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, supplier: response.data.supplier};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', supplier: null};
            });
    }

    async saveSupplier(upload_image){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplier.supplierSave, this.formDataSupplier).then(async (response) => {
            if(upload_image) {
                if (!await saveFile(response.data.id, upload_image, Vue.prototype.$globalSettings.api.endpoints.supplier.supplierSaveFile)) {
                    return {isSuccess: false, message: 'Image upload failed!', supplier: null};
                }
            }
            return {isSuccess: response.data.status === 'success', message: response.data.msg, supplier: response.data.supplier};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', supplier: null};
        });
    }

    async deleteSupplier(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplier.supplierDelete, {
            id: id
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }

    //category===
    async getSupplierCategories(fetch) {
        let supplierCategories = Vue.prototype.$store.getters['common/supplierCategories'];
        if(supplierCategories.length <= 0 || fetch){
            return await axios.get(Vue.prototype.$globalSettings.api.endpoints.supplierCategory.supplierCategories)
                .then((response) => {
                    if(response.data.status === 'success'){
                        supplierCategories = response.data.supplierCategories;
                        Vue.prototype.$store.dispatch('common/setSupplierCategories', {value: supplierCategories});
                    }
                    return {isSuccess: response.data.status === 'success', message: response.data.msg, supplierCategories: response.data.supplierCategories};
                })
                .catch((error) => {
                    processHttpErrors(error, false);
                    return {isSuccess: false, message: 'Something went wrong!', supplierCategories: null};
                });
        }
        return {isSuccess: true, message: '', supplierCategories: supplierCategories};
    }
    async getSupplierCategory(id, mode){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplierCategory.supplierCategory, {
            id: id,
            mode: mode,
        })
            .then((response) => {
                return {isSuccess: response.data.status === 'success', message: response.data.msg, supplierCategory: response.data.supplierCategory};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!', supplierCategory: null};
            });
    }
    async saveSupplierCategory(){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplierCategory.supplierCategorySave, this.formDataSupplierCategory).then(async (response) => {
            await this.getSupplierCategories(true);
            return {isSuccess: response.data.status === 'success', message: response.data.msg, supplierCategory: response.data.supplierCategory};
        }).catch((error) => {
            processHttpErrors(error, true);
            return {isSuccess: false, message: 'Something went wrong!', supplierCategory: null};
        });
    }
    async deleteSupplierCategory(id){
        return await axios.post(Vue.prototype.$globalSettings.api.endpoints.supplierCategory.supplierCategoryDelete, {
            id: id
        })
            .then(async (response) => {
                await this.getSupplierCategories(true);
                return {isSuccess: response.data.status === 'success', message: response.data.msg};
            })
            .catch((error) => {
                processHttpErrors(error, true);
                return {isSuccess: false, message: 'Something went wrong!'};
            })
    }
}