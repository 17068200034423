<template>
    <div class="card card-secondary">
        <div class="card-header" v-if="showFormTitle">{{ formTitle }}</div>
        <ValidationObserver v-slot="{ invalid }">
            <form @submit="saveForm">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-sm-6">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="name">Name *</label>
                                <input type="text" class="form-control" id="name" placeholder="Enter name" v-model="formData.name" required>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="address">Address</label>
                            <input type="text" class="form-control" id="address" placeholder="Enter address" v-model="formData.address">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="phone">Phone</label>
                            <input type="text" class="form-control" id="phone" placeholder="Enter phone" v-model="formData.phone">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="mobile">Mobile</label>
                            <input type="text" class="form-control" id="mobile" placeholder="Enter mobile" v-model="formData.mobile">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="fax">Fax</label>
                            <input type="text" class="form-control" id="fax" placeholder="Enter fax" v-model="formData.fax">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="email">Email</label>
                            <input type="text" class="form-control" id="email" placeholder="Enter email" v-model="formData.email">
                        </div>
                        <div class="form-group col-sm-6">
                            <label for="website">Website</label>
                            <input type="text" class="form-control" id="website" placeholder="Enter website" v-model="formData.website">
                        </div>
                        <div class="form-group col-sm-6">
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <label for="supplier_category_id">Category *</label>
                                <div class="input-group">
                                    <select class="form-control" id="supplier_category_id" v-model="formData.supplier_category_id">
                                        <option v-for="category in categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                    </select>
                                    <div class="input-group-append">
                                        <button type="button" class="btn btn-outline-secondary btn-sm" @click="addMeta('category')"><i class="fas fa-plus"></i></button>
                                    </div>
                                </div>
                                <span>{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="form-group col-sm-6">
                            <div class="row">
                                <div :class="formData.image? 'col-sm-6': 'col-sm-12'">
                                    <label for="upload_image">Image</label>
                                    <input type="file" class="form-control" id="upload_image" ref="upload_image" accept=".jpg,.jpeg,.png,.gif" v-on:change="setUploadFile">
                                </div>
                                <div class="col-sm-6" v-if="formData.image">
                                    <img class="img-thumbnail img-md mt-2" :src="$apiBaseURL + '/' + formData.image" v-if="formData.image" />
                                    <button type="button" class="btn btn-xs text-danger" v-on:click="unsetUploadFile"><i class="far fa-times-circle"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <button type="submit" class="btn btn-sm btn-primary float-right" :disabled="invalid"><i class="fas fa-save"></i> Save</button>
                </div>
            </form>
        </ValidationObserver>

        <ModalGeneral title="" size="modal-lg" :showModal="metaModal.show" :hideModal="hideMetaModal" v-if="metaModal.show">
            <div slot="modal-body">
                <supplier-category :save-handler="metaCreated" v-if="metaModal.type === 'category'"></supplier-category>
            </div>
        </ModalGeneral>
    </div>
</template>

<script>
import Vue from 'vue'

import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import {SupplierService} from "@/services/supplier-service";
import ModalGeneral from "@/components/app/dialog/ModalGeneral";
import SupplierCategory from "@/components/app/supplier/SupplierCategory";

extend('required', {
    ...required,
    message: 'This field is required'
});
extend('realNumber', value => {
    let regexFloatingPoint = /^[+-]?([0-9]*[.])?[0-9]+$/i;
    return regexFloatingPoint.test(value)? true: 'This field must be a number';
});

let supplierService = new SupplierService();
export default {
    name: 'Supplier',
    data: function(){
        return {
            pageName: 'Supplier',
            categories: [],
            upload_image: null,
            formData: {
                id: 0,
                name: null,
                address: null,
                phone: null,
                mobile: null,
                fax: null,
                email: null,
                website: null,
                image: null,
                editable: 1,
                supplier_category_id: null,
                opening_balance: 0,
            },
            formTitle: '',

            metaModal:{
                show: false,
                type: null,
            },
        }
    },
    props: {
        showFormTitle:{
            type: Boolean,
            default: () => true
        },
        id:{
            type: Number,
            default: () => 0
        },
        saveHandler: {
            type: Function,
            default: function () {
                this.$router.push({name: 'App.Suppliers'});
            }
        }
    },
    components: {
        SupplierCategory,
        ModalGeneral,
        ValidationObserver, ValidationProvider
    },
    computed: {

    },
    methods:{
        loadCategories: async function () {
            let loader = this.$loading.show({container: this.$refs.formContainer});
            let supplierCategories = await supplierService.getSupplierCategories(false);
            if (supplierCategories.isSuccess){
                this.categories = supplierCategories.supplierCategories;
                if(this.id === 0 && supplierCategories.supplierCategories.length > 0) {
                    this.formData.supplier_category_id = supplierCategories.supplierCategories[0].id;
                }
            }else{
                Vue.$toast.open({message: supplierCategories.message, type: 'error'});
                await this.$router.push({name: 'App.Suppliers'});
            }
            loader.hide();
        },
        async loadSavedData() {
            if (this.id !== 0) {
                this.formData.id = this.id;
                let loader = this.$loading.show();

                let response = await supplierService.getSupplier(this.id, 'edit');
                if (response.isSuccess) {
                    this.formData = response.supplier;
                } else {
                    Vue.$toast.open({message: response.message, type: 'error'});
                    await this.$router.push({name: 'App.Suppliers'});
                }
                loader.hide();
            }
        },
        setUploadFile(){
            this.upload_image = this.$refs.upload_image.files[0];
        },
        async saveForm(e) {
            e.preventDefault();
            let loader = this.$loading.show();
            supplierService.formDataSupplier = this.formData;
            let response = await supplierService.saveSupplier(this.upload_image);
            if (response.isSuccess) {
                Vue.$toast.open({message: response.message, type: 'success'});
                this.saveHandler(response.supplier);
            }else{
                Vue.$toast.open({message: response.message, type: 'error'});
            }
            loader.hide();
        },
        unsetUploadFile(){
            if(confirm("Are you sure?")) {
                this.formData.image = null;
            }
        },
        addMeta(type){
            this.metaModal.type = type;
            this.metaModal.show = true;
        },
        metaCreated: function (data){
            if(data){
                if(this.metaModal.type === 'category'){
                    this.categories.push(data);
                    this.formData.supplier_category_id = data.id;
                }
            }
            this.hideMetaModal();
        },
        hideMetaModal: function(){
            this.metaModal.type = null;
            this.metaModal.show = false;
            this.metaModal.createdMeta = null;
        }
    },
    mounted() {
        this.formTitle = (this.id === 0? 'Add ': 'Update ') + this.pageName;
        this.loadCategories();
        this.loadSavedData();
    }
}
</script>

<style scoped>

</style>
